@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
    font-size: 16px;
}

.fs-12 {
    font-size: 0.75rem !important;
}

.fs-14 {
    font-size: 0.875rem !important;
}

.fs-16 {
    font-size: 1rem !important;
}
.fs-18 {
    font-size: 1.125rem !important;
}
.fs-20 {
    font-size: 1.25rem !important;
}

.fs-24 {
    font-size: 1.5rem !important;
}
.fs-28 {
    font-size: 1.75rem !important;
}
.fs-30 {
    font-size: 1.875rem !important;
}
.fs-32 {
    font-size: 2rem !important;
}
.fs-40 {
    font-size: 2.5rem !important;
}
.fs-48 {
    font-size: 3rem !important;
}
.fs-64 {
    font-size: 4rem !important;
}
