@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./global.css");
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

.rotate180 {
  transform: rotate(180deg);
}
.bg-light-grey {
  background-color: #f4f4f8;
}

.bg-img {
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}
.login-left-text {
  display: flex;
  height: calc(100vh - 60px);
  align-items: center;
  color: var(--ad-primary-color-l1);
  font-weight: 500;
}
.btn {
  border-radius: 5px;
  border: 1px solid var(--ad-primary-color);
  font-size: 1rem;
  font-weight: 500;
  height: 40px;
}
.btn-primary {
  background-color: var(--ad-primary-color);
  color: var(--ad-primary-color-l1);
}
.btn-primary:not(.disabled):hover {
  color: var(--ad-primary-color-l1) !important;
  background-color: #00b1e0 !important;
  border-color: #00b1e0 !important;
}
.btn-primary.disabled,
.btn-primary.disabled:hover {
  background-color: #d3d3e2 !important;
  border: 1px solid #d3d3e2 !important;
  cursor: not-allowed;
}
.btn-light-red {
  border-color: var(--ad-lightred-color);
  background-color: transparent;
  color: var(--ad-lightred-color);
}
.btn-light-red:hover {
  color: var(--ad-primary-color-l1) !important;
  background-color: #d05f5d !important;
  border-color: #d05f5d !important;
}
.btn-secondary {
  background-color: transparent;
  color: var(--ad-highlight-color);
  border: 1px solid var(--ad-secondary-color-l3);
}
.btn-secondary:hover {
  color: var(--ad-highlight-color) !important;
  background-color: #f4f4f8 !important;
  border-color: var(--ad-secondary-color-l3) !important;
}
.form-input {
  border: 1px solid var(--ad-secondary-color-l3);
  border-radius: 5px;
  color: var(--ad-highlight-color);
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0;
}
.form-input:focus {
  border: 2px solid var(--ad-highlight-color);
  box-shadow: none;
}
.form-input:disabled {
  background-color: #f4f4f8 !important;
}

.user-input {
  position: relative;
}

.user-input .form-label {
  position: absolute;
  pointer-events: none;
  bottom: 9px;
  left: 13px;
  transition: 0.3s ease all;
}
.user-input input:not(:placeholder-shown) ~ .form-label,
.user-input input:focus ~ .form-label {
  bottom: 95%;
  left: 13px;
  opacity: 1;
  padding-bottom: 8px;
  font-size: 0.75rem !important;
}
.loginscreen-left {
  /* width: 32%; */
  /* max-width: 400px; */
}
.password-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.sidebar-menu {
  position: fixed;
  background-color: #f4f4f8;
  top: 0;
  height: 100vh;
  width: 65px;
  z-index: 9;
}
.header {
  position: fixed;
  top: 0;
  width: calc(100vw - 65px);
  box-shadow: 1px 1px 1px #d3d3e2;
  height: 65px;
  left: 65px;
  display: flex;
  justify-content: space-between;
  background-color: var(--ad-primary-color-l1);
  z-index: 9;
}
.small-logo {
  height: 65px;
  box-shadow: 0px 1px 0px #d3d3e2;
}
.profile-icon {
  width: 34px;
  height: 34px;
  color: var(--ad-primary-color-l1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffc25c;
  border-radius: 50%;
}
.vertical-menu {
  margin: 10px 12px;
}
.main-menu .menu,
.vertical-menu li {
  background-color: var(--ad-primary-color-l1);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}
.main-menu .menu:hover,
.navitem.active .main-menu .menu,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
.vertical-menu .ant-menu-submenu-selected {
  background-color: var(--ad-primary-color);
  color: var(--ad-primary-color-l1);
}
.main-menu .menu:hover img,
.navitem.active .main-menu .menu img,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover img,
.vertical-menu .ant-menu-submenu-selected img {
  filter: invert(1) brightness(2);
}
.vertical-menu .ant-menu-item-icon {
  min-width: 18px !important;
  width: 18px;
}
.vertical-menu .ant-menu-submenu-arrow {
  display: none;
}
.vertical-menu li:first-child:after,
.vertical-menu li:last-child:after {
  content: "";
  background-image: url("../public/images/icon/more-menu.svg");
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 12px;
}
.ant-menu .ant-menu-item-group-title {
  font-size: 0.75rem;
  color: #a5a5b1;
}
.main-content {
  padding-top: 65px;
  padding-left: 65px;
}
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item {
  padding-inline: 12px 6px !important;
  width: auto;
  height: 34px;
  line-height: 34px;
}
.ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: 10px;
  padding-inline-end: 30px;
}
.subitem,
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item .nodatatext {
  color: var(--ad-primary-color-l3);
}
.ant-menu .ant-menu-title-content {
  font-size: 1rem;
  color: var(--ad-primary-color-l3);
}
.subitem:hover,
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item:hover {
  background-color: #f4f4f8 !important;
  border-radius: 3px;
}
.custom-tabs {
  border: 0;
}
.ant-tabs .ant-tabs-tab,
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 15px 0 15px !important;
  padding: 6px 0 !important;
}
.custom-tabs .nav-link,
.ant-tabs-tab-btn {
  color: var(--ad-secondary-color-l1) !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  border-radius: 0;
  border: 0;
}
.custom-tabs .nav-link.active,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--ad-highlight-color) !important;
}
.custom-tabs .nav-link:hover,
.custom-tabs .nav-link.active {
  background-color: transparent;
}
.ant-tabs-ink-bar {
  background: transparent !important;
}
.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.tab-container {
  height: calc(100vh - 110px);
}

.upload-btn-dropdown {
  background-color: var(--ad-primary-color);
  color: var(--ad-primary-color-l1);
  border: 1px solid var(--ad-primary-color);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 38px !important;
}

.upload-btn,
.upload-btn.active,
.upload-btn:hover {
  background-color: transparent !important;
  color: var(--ad-primary-color) !important;
  border: 1px solid var(--ad-primary-color) !important;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 38px !important;
}
.upload-btn:hover {
  background-color: #e9f8fd !important;
}
.upload-file {
  position: absolute;
  left: 0;
  width: 100px;
  height: 36px;
  top: 0;
  visibility: hidden;
}
.tooltip-inner {
  text-align: left;
  font-size: 0.75rem;
}
.discard-btn {
  background-color: transparent !important;
  border-color: var(--ad-primary-color-l1) !important;
  color: var(--ad-primary-color-l1) !important;
}
.discard-btn:hover {
  background-color: #ffffff40 !important;
}
.goto-upload-history-btn {
  background-color: transparent !important;
  border-color: var(--ad-primary-color-l1) !important;
  color: var(--ad-secondary-color-l3) !important;
}
.goto-upload-history-btn:hover {
  background-color: #ffffff40 !important;
}
.proceed-btn {
  background-color: #f4f4f8 !important;
  border-color: #f4f4f8 !important;
  color: var(--ad-highlight-color) !important;
}
.custom-chip {
  background-color: #02bef210;
  border-radius: 3px;
}
/* .customtable {
  max-height: calc(100vh - 240px) !important;
}
.cust_datatable.customtable {
  max-height: calc(100vh - 225px) !important;
} */
.customtable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th.ant-table-cell-fix-right {
  background-color: white;
}
.customtable.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  font-size: 0.875rem;
  color: #a0a4b3;
  font-weight: 500;
  padding: 5px 5px 5px 15px;
  background-color: white;
}
.customtable.ant-table-wrapper .ant-table-tbody > tr > td {
  color: #000;
  font-size: 0.875rem;
  padding: 10px 5px 10px 15px;
}
.mytask-drawer-table.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 5px 10px 15px;
}
.customtable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(.ant-table-cell-fix-right):hover {
  background-color: transparent;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}
.customtable.ant-table-wrapper .ant-table-column-sorter {
  display: none;
}
.customtable.ant-table-wrapper .ant-table-empty table .ant-table-tbody {
  height: calc(100vh - 240px);
}
.cust_datatable.customtable.ant-table-wrapper
  .ant-table-empty
  table
  .ant-table-tbody {
  height: calc(100vh - 293px);
}
.search-input {
  border-radius: 5px;
  font-size: 0.875rem;
  color: var(--ad-highlight-color);
  width: 355px;
  height: 42px;
}
.search-input:hover,
.search-input:focus {
  border: 1px solid var(--ad-secondary-color-l1);
  z-index: 0 !important;
}
.search-input .ant-input-suffix .ant-input-clear-icon {
  margin-top: -5px;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  box-shadow: none;
  border-color: var(--ad-highlight-color);
}
.ant-input-group-addon {
  background-color: transparent !important;
  height: 37px;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-left: 0;
  height: 37px;
}
.verticle-center {
  top: 50%;
  transform: translateY(-50%);
}
.search-input::placeholder {
  color: var(--ad-secondary-color-l1);
  font-size: 14px;
}
.export-btn {
  border-radius: 5px;
  border: 1px solid var(--ad-secondary-color-l3);
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.export-btn:hover {
  border-color: var(--ad-highlight-color);
}
.informative-chip {
  background-color: var(--ad-highlight-color);
  color: var(--ad-secondary-color-l3);
  font-size: 0.875rem !important;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
.informative-close {
  filter: invert(1);
  width: 10px;
}
.status-chip {
  color: #06ba63;
  background-color: #06bc641a;
  border-radius: 3px;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 3px 6px;
  display: inline-block;
}
.status-chip.yellow {
  background-color: #ffc7571a;
  color: #ffc759;
}
.status-chip.blue {
  background-color: #02bef21a;
  color: #03c1f3;
}
.status-chip.red {
  background-color: #e8615e1a;
  color: #e85f5c;
}
.status-chip.gray {
  background-color: #9fa3b21a;
  color: #a0a4b3;
}
.logoutTooltip .ant-popover-inner {
  padding: 0;
}
.ant-picker-range {
  width: 220px;
  height: 37px;
}
.ant-drawer .ant-drawer-title {
  color: var(--ad-primary-color-l3);
  font-size: 1.5rem;
  font-weight: 500;
}
.myTaskMatchsButton
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  div {
  padding: 8px 18px;
  border: 1px solid #03c1f3;
  font-size: 14px !important;
  border-radius: 5px;
  color: #03c1f3 !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
}
.myTaskMatchsButton
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  div {
  padding: 8px 18px;
  background-color: #03c1f31a;
  border: 1px solid #03c1f3;
  font-size: 14px !important;
  border-radius: 5px;
  color: #03c1f3 !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
}
.custom-filter-date-picker .ant-picker-input {
  width: 10rem;
  display: flex;
  flex-direction: row-reverse;
}
.custom-filter-date-picker .ant-picker-input span.ant-picker-suffix,
.custom-filter-date-picker .ant-picker-input input {
  margin-left: 0;
  margin-right: 8px;
}
.custom-filter-drawer-main .ant-drawer-body {
  padding: 0;
}
.custom-notification-drawer-main .ant-drawer-body {
  padding: 0;
}
.custom-antd-pagination {
  padding-right: 1rem;
}
.custom-antd-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  min-width: 25px !important;
  min-height: 25px !important;
  height: 25px;
  line-height: 23px;
  border: 0;
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover,
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  background-color: transparent;
}
.custom-antd-pagination .ant-pagination-next,
.custom-antd-pagination .ant-pagination-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0; */
}
.custom-antd-pagination .ant-pagination-prev button,
.custom-antd-pagination .ant-pagination-next button {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.custom-antd-pagination .ant-pagination-options .ant-select-selector {
  padding: 0 15px !important;
}
.custom-antd-pagination .ant-pagination-options::before {
  content: "Rows per page";
  margin: auto;
  padding-right: 1rem;
  font-size: 0.75rem !important;
}
.custom-antd-pagination .ant-pagination-total-text {
  order: 0;
  display: flex;
}
.custom-antd-pagination .ant-pagination-options {
  display: flex;
  order: -1;
  margin-right: 1rem;
  gap: 8px;
}
.custom-antd-pagination
  .ant-select.ant-pagination-options-size-changer
  .ant-select-selector
  .ant-select-selection-search
  input[type="search"] {
  opacity: 0;
  cursor: pointer;
}
.custom-antd-pagination
  .ant-select.ant-pagination-options-size-changer
  .ant-select-selector {
  cursor: pointer !important;
}

/* .custom-antd-pagination
  .ant-select.ant-pagination-options-size-changer
  .ant-select-dropdown {
  display: none;
} */

/* toast message css */

.ant-message {
  top: inherit !important;
  bottom: 20px;
}

.ant-message-notice .ant-message-notice-content {
  color: #fff;
  border-radius: 8px;
  width: 30%;
  text-align: left;
  padding: 15px 20px;
}

.ant-message-notice span.anticon {
  display: none;
}

.info-toast-msg .ant-message-notice-content {
  background-color: #5a6268 !important;
}

.success-toast-msg .ant-message-notice-content {
  background-color: #06ba63 !important;
}

.error-toast-msg .ant-message-notice-content {
  background-color: #d95128 !important;
}

/* toast message css ends */

.dropdown-download-item:hover {
  background-color: #f4f4f8;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: black !important;
  border-inline-end-width: undefinedpx !important;
}
.ant-pagination-options-quick-jumper input[type="text"]:hover,
:focus {
  border-color: black !important;
  box-shadow: unset;
}
.ant-input:hover {
  border-color: black !important;
}
.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #000000;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 0%);
}
.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon:hover {
  line-height: 20px;
}
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon:hover {
  line-height: 20px;
}
.ant-picker-range,
.ant-picker-input input {
  cursor: pointer;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #000000;
}
.activity-marked-text-auto-recon-bg {
  background-color: #f2fcff;
}
.activity-marked-text-bold {
  font-weight: 500;
  color: var(--ad-primary-color);
}
.selection-checkbox-table th.ant-table-selection-column {
  visibility: hidden;
}
.custom-select .ant-select-selector {
  height: 38px !important;
  align-items: center;
}
.showprogressbar {
  line-height: 1;
  margin-top: 5px;
}
.ant-progress-line {
  font-size: 0px;
  margin-inline-end: 0px;
  margin-bottom: 0px;
}
.setting {
  background: rgba(0, 0, 0, 0.25);
}
.div-disabled {
  cursor: not-allowed !important;
  color: #d3d3e2;
}

span.ant-tag.tag-disabled {
  cursor: not-allowed !important;
  color: #d3d3e2;
  opacity: 0.6;
}
