.primary-text-color {
  color: var(--ad-primary-color);
}
.secondary-text-color {
  color: var(--ad-secondary-color);
}
.secondary-color-text-l1 {
  color: var(--ad-secondary-color-l1);
}
.label-error-color {
  color: #e85f5c;
}
.highlight-text-color {
  color: var(--ad-highlight-color);
}
.primary-text-color-l3 {
  color: var(--ad-primary-color-l3);
}
.lightred-text-color {
  color: var(--ad-lightred-color);
}
.lightgrey-bg-color {
  background-color: var(--ad-lightgrey-bg-color);
}
.error {
  font-size: 0.75rem;
  color: #e85f5c;
  margin-top: 5px;
  padding-left: 13px;
}
.form-input.error {
  border: 2px solid #e85f5c;
}
.cursor-pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #edf0f2;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8c2bd;
  border-radius: 5px;
}
.custom-disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #d3d3e2 !important;
  border: 1px solid #d3d3e2 !important;
  cursor: not-allowed;
  border: none !important;
}

.loader {
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  position: absolute;
  background-color: rgba(250, 250, 250, 0.4);
  width: calc(100vw + 70px);
  left: -70px;
  top: 0;
  height: 100vh;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader img {
  width: 150px;
  height: 150px;
}
.loader-spin.ant-spin-nested-loading .ant-spin-blur {
  opacity: 1;
}
.loader-spin.ant-spin-nested-loading .ant-spin-blur .ant-table-body,
.loader-spin.ant-spin-nested-loading
  .ant-spin-blur
  .ant-pagination.ant-table-pagination.ant-table-pagination-right.custom-antd-pagination {
  opacity: 0;
}
.loader-spin.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  top: 54%;
}

.ant-table-wrapper .ant-table-thead > tr > th:has(.columnBorder) {
  margin-top: 20px;
  border-bottom: 1px solid black;
}
