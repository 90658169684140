[data-theme="dark"] {
    --ad-primary-color: #03C1F3;
    --ad-secondary-color: #0F63AC;
    --ad-highlight-color: #031728;
    --ad-secondary-color-l1: #A0A4B3;
    --ad-primary-color-l1: #FFFFFF;
    --ad-primary-color-l2: #000000;
    --ad-secondary-color-l2: #707070;
    --ad-secondary-color-l3: #D3D3E2;
    --ad-primary-color-l3: #383B3C;
    --ad-lightgrey-bg-color:#F9F9FB;
    --ad-lightred-color:#E85F5C;
}
